import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import useIsVisible from "../../hooks/isVisible";
import { ButtonFrg } from "../../fragments";
import { Button } from "../common/btn";
import { PortableText } from "@portabletext/react";
import { ElfsightWidget } from "react-elfsight-widget";

const Footer = () => {
  const { sanityFooter } = useStaticQuery(graphql`
    {
      sanityFooter {
        logo {
          asset {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 150
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        button {
          ...ButtonFrg
        }
        helpLinks {
          __typename
          menutitle
          slug {
            current
          }
        }
        contactSection {
          title
          phoneIcon {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          phoneTitle
          phoneNo
          # mobileIcon {
          #   asset {
          #     gatsbyImageData(
          #       layout: CONSTRAINED
          #       placeholder: BLURRED
          #       formats: WEBP
          #     )
          #   }
          # }
          # mobileTitle
          # mobileNo
          emailIcon {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          email
          addressIcon {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          _rawAddress
        }
        disclaimer
        socialLinks {
          title
          url
          iconImage {
            asset {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 50
                height: 50
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
      }
    }
  `);
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);
  const contactSec = sanityFooter.contactSection;

  // const { myProfile } = useContext(CartContext);

  return (
    <div className="border-t-2 border-t-gold border-solid flex flex-col">
      <div className="w-full px-3 sm:px-10 flex items-start justify-between py-[42px] md:py-20 flex-col space-y-10 md:space-y-0 md:flex-row">
        <div className="space-y-10 sm:space-y-0 sm:space-x-[99px] md:space-x-[106px] flex flex-col sm:flex-row mt-[31px] md:mt-0">
          <div className="flex flex-col max-w-xs space-y-3">
            <div>
              <GatsbyImage
                image={sanityFooter.logo.asset.gatsbyImageData}
                alt="Vista Gardens"
                className="w-28"
                objectFit="contain"
              />
            </div>
            <div target="_blank" className="flex space-x-3">
              <GatsbyImage
                image={contactSec.addressIcon.asset.gatsbyImageData}
                alt={contactSec.email}
                className="w-7 h-7 object-contain"
                objectFit="contain"
              />
              <div className="text-white text-lg whitespace-pre-wrap">
                <PortableText value={contactSec._rawAddress} />
              </div>
            </div>
            <div className="w-fit">
              <Button btn={sanityFooter.button} />
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            {/* <h6 className="text-base leading-[26px] capitalize font-medium">
              Help
            </h6> */}
            {sanityFooter.helpLinks.map((link, index) => (
              <Link
                key={index}
                to={
                  link.slug.current.startsWith("/")
                    ? link.slug.current
                    : `/${link.slug.current}`
                }
                className="text-base leading-[26px] capitalize font-light"
              >
                {link.menutitle}
              </Link>
            ))}
          </div>
        </div>
        <div className="xl:flex-[0.5] gap-5 flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row">
          <div className="inline-block lg:w-2/5">
            <ElfsightWidget widgetId="0cd6f057-a6ae-4d02-af00-4b034ba92c19" />
          </div>
          <div className="inline-block">
            <div className="block">
              <h4 className="text-xl font-georgia font-light mb-4 text-gold">
                Contact Us
              </h4>
              <div className="flex flex-col space-y-4">
                <a
                  href={contactSec.phoneNo}
                  target="_blank"
                  rel="norefferer"
                  className="flex space-x-3"
                >
                  <GatsbyImage
                    image={contactSec.phoneIcon.asset.gatsbyImageData}
                    alt={contactSec.phoneTitle}
                    className="w-7 h-7 object-contain"
                    objectFit="contain"
                  />
                  <span>{contactSec.phoneTitle}</span>
                </a>
                {/* <a
                href={contactSec.mobileNo}
                target="_blank"
                rel="norefferer"
                className="flex space-x-3"
              >
                <GatsbyImage
                  image={contactSec.mobileIcon.asset.gatsbyImageData}
                  alt={contactSec.mobileTitle}
                  className="w-7 h-7 object-contain"
                  objectFit="contain"
                />
                <span>{contactSec.mobileTitle}</span>
              </a> */}
                <a
                  href={`mailto:${contactSec.email}`}
                  target="_blank"
                  rel="norefferer"
                  className="flex space-x-3"
                >
                  <GatsbyImage
                    image={contactSec.emailIcon.asset.gatsbyImageData}
                    alt={contactSec.email}
                    className="w-7 h-7 object-contain"
                    objectFit="contain"
                  />
                  <span>{contactSec.email}</span>
                </a>
              </div>
            </div>
            <div className="flex space-x-1 mt-5">
              {sanityFooter.socialLinks.map((social, key) => (
                <a href={social.url} target="_blank" key={key} className="">
                  <GatsbyImage
                    image={social.iconImage.asset.gatsbyImageData}
                    alt={social.title}
                    objectFit="contain"
                    className="w-14 h-14 object-contain"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="py-5 border-t border-solid border-t-white/45 px-5 sm:px-10 text-center">
        <p>{sanityFooter.disclaimer}</p>
      </div>
      {/* Subfooter */}
      {/* <div className="w-full h-[91px] md:h-20 flex items-center justify-between pl-2 sm:pl-6 pr-3 sm:pr-11 border-t border-solid border-t-white/50">
        <div>
          <GatsbyImage
            image={sanityFooter.logo.asset.gatsbyImageData}
            alt="Goodlight"
            className="w-[132px] h-9"
            objectFit="cover"
          />
          <p className="text-[9.5px] ml-[14px] font-light">
            {sanityFooter.logoCaption}
          </p>
        </div>
        <div className="md:space-x-10 w-40 md:w-auto flex flex-col md:flex-row items-start md:items-center">
          {sanityFooter.subfooterLinks.map((link, index) => (
            <Link
              key={index}
              to={
                link.slug.current.startsWith("/")
                  ? link.slug.current
                  : `/${link.slug.current}`
              }
              className="text-sm sm:text-base leading-[26px] capitalize font-light"
            >
              {link.menutitle}
            </Link>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
