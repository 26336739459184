import { Link } from "gatsby";
import React from "react";
// import Navigation from "./Navigation";

const NavLinks = ({ data }) => {
  const path = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <nav className="hidden lg:flex items-center md:flex-auto justify-center text-base xl:text-lg">
      {data.sanityMenu.navMenu.map((item) => (
        <Link
          key={item.key}
          className={`first-of-type:ml-0 ml-[18px] pb-[2px] flex items-center h-[72px] text-gold`}
          activeClassName="active_link"
          to={
            item.slug.current.startsWith("/")
              ? item.slug.current
              : `/${item.slug.current}`
          }
        >
          <span>{item.menutitle}</span>
        </Link>
      ))}
    </nav>
  );
};

export default NavLinks;
