import React, { createContext, useState } from "react";

const contextDefaultValues = {
  searchResults: [],
  setSearchResults: () => {},
};

const CartContext = createContext(contextDefaultValues);

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const SearchProvider = ({ children }) => {
  let [searchResults, setSearchResults] = useState([]);

  // const data = useStaticQuery(graphql`
  //   {
  //     shopifyCollection(handle: { eq: "good-light-products" }) {
  //       products {
  //         id
  //         title
  //         handle
  //         featuredImage {
  //           src
  //         }
  //         priceRangeV2 {
  //           minVariantPrice {
  //             amount
  //           }
  //           maxVariantPrice {
  //             amount
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <CartContext.Provider
      value={{
        searchResults,
        setSearchResults,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const SearchConsumer = CartContext.Consumer;
export default SearchProvider;
export { SearchConsumer, CartContext };
