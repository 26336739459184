import React, { Fragment } from "react";
import Footer from "./footer";
import PromoBar from "./promobar";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <div className="relative max-w-[100vw] w-full h-full mx-auto">
        <PromoBar />
        <div className="w-full mx-auto mt-[145px] ">
          {/* <Navbar /> */}
          {children}

          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
