import { Link } from "gatsby";
import React, { useState } from "react";

const Btn = ({ btnType, btnClr, textClr, btnText, btnLink }) => {
  const cls = `min-w-[187px] h-11 text-center flex items-center justify-center px-4 py-3 text-lg border border-solid rounded-[4px] block`;
  // const solid = `${
  //   btnClr === "white"
  //     ? "bg-white text-[#313131] border-white hover:bg-transparent hover:text-white"
  //     : btnClr === "gray"
  //     ? "bg-[#313131] text-white border-[#313131]"
  //     : "bg-black text-white border-black hover:bg-transparent hover:text-black hover:border hover:border-solid hover:border-black"
  // }`;
  // const outline = `${
  //   btnClr === "white"
  //     ? "bg-transparent text-white border-white hover:bg-white/5"
  //     : btnClr === "gray"
  //     ? "bg-transparent text-[#313131] border-[#313131] hover:bg-[#313131]/5"
  //     : "bg-transparent text-black border-black hover:bg-black/5"
  // }`;

  const bgColor = btnClr?.rgb
    ? `rgba(${btnClr.rgb.r}, ${btnClr.rgb.g}, ${btnClr.rgb.b}, ${btnClr.rgb.a})`
    : "#000";

  const textColor = textClr?.rgb
    ? `rgba(${textClr.rgb.r}, ${textClr.rgb.g}, ${textClr.rgb.b}, ${textClr.rgb.a})`
    : "#fff";

  if (btnType === "outline") {
    return (
      <Link
        className={`transition-colors ease-linear duration-150 ${cls}`}
        to={btnLink}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        {btnText}
      </Link>
    );
  }
  return (
    <Link
      className={`transition-colors ease-linear duration-150 ${cls}`}
      to={btnLink}
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {btnText}
    </Link>
  );
};

export const Button = ({ btn }) => {
  const [hover, setHover] = useState(false);

  const backgroundColor = btn?.bgColor ? `${btn?.bgColor}` : "#000";
  const bgOpacity = btn?.bgOpacity ?? "ff";
  const txtColor = btn?.textColor ? `${btn?.textColor}` : "#fff";
  const bdrColor = btn?.borderColor ? `${btn?.borderColor}` : "";
  const hoverBackgroundColor = btn?.bgHoverColor
    ? `${btn?.bgHoverColor}`
    : "#000";
  const bgHoverOpacity = btn?.bgHoverOpacity ?? "ff";
  const hoverTextColor = btn?.textHoverColor
    ? `${btn?.textHoverColor}`
    : "#fff";
  const hoverBorderColor = btn?.borderHoverColor
    ? `${btn?.borderHoverColor}`
    : "";

  const cls = `min-w-[227px] h-14 text-center flex items-center justify-center px-5 py-3 text-lg md:text-xl border border-solid rounded-[4px] block`;

  if (btn?.buttonType === "outline") {
    if (btn?.linkType === "external") {
      return (
        <a
          className={`transition-colors ease-linear duration-150 ${cls}`}
          href={btn?.link}
          target="_blank"
          style={{
            // backgroundColor: bgColor,

            color: txtColor ? (hover ? hoverTextColor : txtColor) : "white",
            border: hover
              ? btn?.borderHoverWidth > 0
                ? `${btn?.borderHoverWidth}px solid ${hoverBorderColor}`
                : "none"
              : btn?.borderWidth > 0
              ? `${btn?.borderWidth}px solid ${bdrColor}`
              : "none",
          }}
        >
          {btn?.buttonText}
        </a>
      );
    } else {
      return (
        <Link
          className={`transition-colors ease-linear duration-150 ${cls}`}
          to={btn?.path.startsWith("/") ? btn?.path : `/${btn?.path}`}
          style={{
            // backgroundColor: bgColor,
            color: txtColor ? (hover ? hoverTextColor : txtColor) : "white",
            border: hover
              ? btn?.borderHoverWidth > 0
                ? `${btn?.borderHoverWidth}px solid ${hoverBorderColor}`
                : "none"
              : btn?.borderWidth > 0
              ? `${btn?.borderWidth}px solid ${bdrColor}`
              : "none",
          }}
        >
          {btn?.buttonText}
        </Link>
      );
    }
  } else {
    if (btn?.linkType === "external") {
      return (
        <a
          className={`transition-colors ease-linear duration-150 ${cls}`}
          href={btn?.link}
          target="_blank"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            backgroundColor: backgroundColor
              ? hover
                ? hoverBackgroundColor + bgHoverOpacity
                : backgroundColor + bgOpacity
              : "black",
            color: txtColor ? (hover ? hoverTextColor : txtColor) : "white",
            border: hover
              ? btn?.borderHoverWidth > 0
                ? `${btn?.borderHoverWidth}px solid ${hoverBorderColor}`
                : "none"
              : btn?.borderWidth > 0
              ? `${btn?.borderWidth}px solid ${bdrColor}`
              : "none",
          }}
        >
          {btn?.buttonText}
        </a>
      );
    } else {
      return (
        <Link
          className={`transition-colors ease-linear duration-150 ${cls}`}
          to={btn?.path.startsWith("/") ? btn?.path : `/${btn?.path}`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            backgroundColor: backgroundColor
              ? hover
                ? hoverBackgroundColor + bgHoverOpacity
                : backgroundColor + bgOpacity
              : "black",
            color: txtColor ? (hover ? hoverTextColor : txtColor) : "white",
            border: hover
              ? btn?.borderHoverWidth > 0
                ? `${btn?.borderHoverWidth}px solid ${hoverBorderColor}`
                : "none"
              : btn?.borderWidth > 0
              ? `${btn?.borderWidth}px solid ${bdrColor}`
              : "none",
          }}
        >
          {btn?.buttonText}
        </Link>
      );
    }
  }
};

export default Btn;
