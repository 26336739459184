import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { ButtonFrg } from "../../../fragments";
import React, { useEffect, useState } from "react";
import NavLinks from "./Desktop/NavLinks";
import RightSideIcons from "./Desktop/RightSideIcons";
import DesktopSearch from "./Desktop/Search";
import Sidebar from "./Mobile/Sidebar";
import { searchData } from "../../../utils/search";

const Navbar = () => {
  const data = useStaticQuery(graphql`
    {
      sanityMenu {
        id
        logo {
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        altText
        navMenu {
          key: _key
          menutitle
          slug {
            current
          }
        }
        ctaBtn {
          ...ButtonFrg
        }
      }
      allSanityBlog {
        nodes {
          _type
          title
          _rawBody
          blogSlug {
            current
          }
          thumbnailImage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
                formats: WEBP
              )
            }
          }
        }
      }
      allSanityQas {
        nodes {
          _type
          title: question
          _rawBody: _rawAnswer
        }
      }
    }
  `);

  const allData = [...data.allSanityBlog.nodes, ...data.allSanityQas.nodes];

  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  function handleSidebar() {
    setSidebar(!isOpen);
  }

  function handleSearch(e) {
    e.preventDefault();
    navigate(`/search?s=${encodeURIComponent(search)}`);
    setIsOpen(false);
    setSearch("");
  }

  useEffect(() => {
    if (search) {
      const results = searchData(search, allData);
      setSearchResults(results);
    }
  }, [search]);

  return (
    <div className="relative w-full">
      <div className="w-full  px-6 sm:px-10 flex items-center justify-between text-white">
        {/* Logo */}
        <Link className="" to="/">
          {data.sanityMenu?.logo?.asset?.gatsbyImageData ? (
            <GatsbyImage
              className="w-20 h-20 sm:w-24 sm:h-24 object-contain"
              image={data.sanityMenu.logo.asset.gatsbyImageData}
              alt={data.sanityMenu.altText || "Logo"}
              loading="eager"
              objectFit="contain"
            />
          ) : (
            <span className="font-georgia text-3xl font-bold">
              {data.sanityMenu?.altText}
            </span>
          )}
        </Link>
        {/* Links */}
        <NavLinks data={data} />
        {/* Sidebar */}
        <Sidebar
          data={data}
          isOpen={sidebar}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          setIsOpen={setSidebar}
          results={searchResults}
        />
        {isOpen && (
          <DesktopSearch
            search={search}
            setSearch={setSearch}
            setIsOpen={setIsOpen}
            handleSearch={handleSearch}
            results={searchResults}
          />
        )}
        {/* Menu Bar */}
        <div className="block sm:hidden text-white" onClick={handleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>

        {/* Icons */}
        <RightSideIcons
          setIsOpen={setIsOpen}
          data={data}
          handleSidebar={handleSidebar}
        />
      </div>
    </div>
  );
};

export default Navbar;
