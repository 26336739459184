import { Link } from "gatsby";
import React from "react";
import { Button } from "../../btn";

const RightSideIcons = ({ setIsOpen, data, handleSidebar }) => {
  return (
    <div className="hidden sm:flex items-center md:max-w-sm justify-end space-x-4 ">
      <div className="hidden sm:block text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          onClick={() => setIsOpen(true)}
          aria-label="search-icon"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </div>

      <div className="">
        <Button btn={data.sanityMenu.ctaBtn} />
      </div>
      <button className="block lg:hidden text-white" onClick={handleSidebar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>
  );
};

export default RightSideIcons;
