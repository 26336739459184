exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-bookstore-jsx": () => import("./../../../src/pages/bookstore.jsx" /* webpackChunkName: "component---src-pages-bookstore-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-garden-map-jsx": () => import("./../../../src/pages/garden-map.jsx" /* webpackChunkName: "component---src-pages-garden-map-jsx" */),
  "component---src-pages-gardens-jsx": () => import("./../../../src/pages/gardens.jsx" /* webpackChunkName: "component---src-pages-gardens-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-membership-jsx": () => import("./../../../src/pages/membership.jsx" /* webpackChunkName: "component---src-pages-membership-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-pages-tutorials-jsx": () => import("./../../../src/pages/tutorials.jsx" /* webpackChunkName: "component---src-pages-tutorials-jsx" */),
  "component---src-pages-visit-jsx": () => import("./../../../src/pages/visit.jsx" /* webpackChunkName: "component---src-pages-visit-jsx" */),
  "component---src-pages-volunteer-jsx": () => import("./../../../src/pages/volunteer.jsx" /* webpackChunkName: "component---src-pages-volunteer-jsx" */),
  "component---src-product-page-index-jsx": () => import("./../../../src/ProductPage/index.jsx" /* webpackChunkName: "component---src-product-page-index-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-location-jsx": () => import("./../../../src/templates/location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */)
}

