import { Link } from "gatsby";
import React, { Fragment, useContext } from "react";
import { CartContext } from "../../../../context/shopContext";
import { Button } from "../../btn";
import { GatsbyImage } from "gatsby-plugin-image";

const Sidebar = ({
  data,
  isOpen,
  search,
  setSearch,
  handleSearch,
  setIsOpen,
  results,
}) => {
  const { searchResults } = useContext(CartContext);

  return (
    <div
      className={`${
        isOpen ? "left-0" : "-left-[100vw]"
      } fixed top-0 z-20 w-screen h-screen bg-[black/30]`}
      onClick={() => setIsOpen(false)}
    >
      <div
        className={`fixed top-[145px] sm:top-[160px] ${
          isOpen ? "left-0" : "-left-80"
        } h-screen flex flex-col max-w-[320px] w-full lg:hidden bg-black z-20 transform transition-all duration-300
        ease-linear border-none`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative pt-5 pl-10">
          {/* Close Icon */}
          <button
            className="absolute top-5 right-4 text-white"
            aria-label="button"
            onClick={() => setIsOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
          {/* Search */}
          <div className="w-56 h-12 flex items-center mb-5 mt-0 bg-black shadow-sm shadow-gold/50 border-2 border-solid border-gold rounded-md">
            <input
              type="text"
              placeholder="Search"
              className="flex-1 h-full rounded-full bg-transparent focus:outline-none text-white outline-none border-none ring-0 focus:ring-0 px-2"
              value={search}
              autoFocus
              onChange={(e) => setSearch(e.target.value)}
            />
            {/* Close Icon */}
            {search && (
              <Fragment>
                <svg
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-1"
                  fill="none"
                  stroke="#fff"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  onClick={() => setSearch("")}
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path
                    fill="currentColor"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                  ></path>
                </svg>
              </Fragment>
            )}
          </div>
          {search && results.length > 0 && (
            <div className="w-full h-auto py-3 mt-2 z-30 relative">
              <ul className="space-y-2 text-white">
                <h6 className="text-xs">Products</h6>
                {results
                  .filter((item) => item._type === "blog")
                  .slice(0, 2)
                  .map((result, index) => (
                    <li
                      className="w-full flex items-center py-2 px-2 hover:bg-black/90"
                      key={index}
                      onClick={() => setIsOpen(false)}
                    >
                      <Link
                        className="text-white flex items-center"
                        to={`/${result.blogSlug.current}`}
                      >
                        {result.thumbnailImage ? (
                          <GatsbyImage
                            image={result.thumbnailImage?.asset.gatsbyImageData}
                            alt={result.title}
                            className="h-10 w-10 object-contain overflow-hidden"
                          />
                        ) : (
                          <div className="h-10 w-10 object-contain overflow-hidden"></div>
                        )}
                        <h5 className="ml-5 text-sm w-[calc(100%-40px)]">
                          {result.title}
                        </h5>
                      </Link>
                    </li>
                  ))}
                {search && results.length > 2 && (
                  <div className="w-full border-t text-white border-solid border-gray-500">
                    <button
                      className="px-2 text-sm underline bg-none my-2"
                      onClick={handleSearch}
                    >
                      View all
                    </button>
                  </div>
                )}
              </ul>
            </div>
          )}
          <ul className="w-full ml-0 space-y-1 lg:hidden uppercase font-medium pr-10">
            {data.sanityMenu.navMenu.map((item, index) => (
              <Fragment key={index}>
                <li
                  key={item.key}
                  className={`flex items-center border-b border-solid border-b-[#d3d3d3d8] py-1`}
                  onClick={() => setIsOpen(false)}
                >
                  <Link
                    className="text-white pb-1"
                    activeClassName="active_link"
                    to={
                      item.slug.current.startsWith("/")
                        ? item.slug.current
                        : `/${item.slug.current}`
                    }
                  >
                    {item.menutitle}
                  </Link>
                </li>
              </Fragment>
            ))}

            <div className="w-fit !mt-5">
              <Button btn={data.sanityMenu.ctaBtn} />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
