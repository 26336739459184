export const searchData = (query, data) => {
  return data.filter((item) => {
    console.log(blocksToText(item._rawBody));
    return (
      item.title.toLowerCase().includes(query.toLowerCase()) ||
      blocksToText(item._rawBody).toLowerCase().includes(query.toLowerCase())
    );
  });
};

const defaults = { nonTextBehavior: "remove" };

export function blocksToText(blocks, opts = {}) {
  const options = Object.assign({}, defaults, opts);
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return options.nonTextBehavior === "remove"
          ? ""
          : `[${block._type} block]`;
      }

      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

export function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
